import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Usage Graphicon contains a collection of case studies we find in Telkom Indonesia digital products. In this section, we will provide examples of graphicon according to the background, goals, and persona of each product. We will divide the case studies into Business-to-Business (B2B), Business-to-Consumer (B2C), and Government-to-Citizen (G2C).`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Usage Graphicon`}</strong>{`: This section will explain the use of styles in graphicons. You need to pay attention because there are directions for using graphicons for various digital products.`}</p>
    </InlineNotification>
    <h2>{`Study Case: Business-to-Business (B2B)`}</h2>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/b2b.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <p>{`B2B (Business-to-Business) products have various visual appearances depending on the product, objectives, and user preferences. However, several common styles are often used in Telkom B2B products and included in design instructions:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`B2B products are usually targeted at Gen-X to Millennials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We recommend using a professional, elegant, and minimalist style.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use clear and simple shapes to avoid too complex or confusing elements.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Study Case: Business-to-Consumer (B2C)`}</h2>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/b2c.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <p>{`B2C (Business-to-Consumer) products with a wide range of target users can be an interesting challenge because we need to consider different target user segments with various preferences and needs. These are several common styles are often used in Telkom B2C products and included in design instructions:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`B2B products are usually targeted at Millenials to Gen-Z.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We recommend using a playful, modern, and creative style.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Recommendations for using colors include flat designs with Two Tone or Three Tone colors.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use clear and simple shapes to avoid too complex or confusing elements.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Study Case: Government-to-Citizen (G2C)`}</h2>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/g2c.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <p>{`G2C (Government-to-Citizen) products are intended for public service use. So, the graphicon must summarize the essence of the relationship between the government and a wide range of citizens. Graphicon must reflect openness, easy accessibility, and friendly public service. These are several common styles are often used in Telkom G2C products and included in design instructions:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`G2C products are usually targeted at Gen-X to Gen-Z.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We recommend using a formal, minimalist, and user-friendly style.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Recommendations for using colors include flat designs and gradients.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use clear and simple shapes to avoid too complex or confusing elements.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Unit & Consistency`}</h2>
    <p>{`It is essential to pay attention to consistent concept and execution to maintain unity across the graphicon. You can easily place graphicon among others and evaluate to what extent they integrate well.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/unit-n-consistency.svg",
      "alt": "grid and keyline illustration"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      